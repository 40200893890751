import React from "react";
import "./Tabs.css";


const TabHeader = ({ data, activeId, click }) => {
	const doClick = (e, index) => {
		click(index);
	};

	let activeClass = activeId;

	let tabs = data.map((item, index) => {
		return (
			<li key={index} className={activeClass === index ? "active" : ""}>
				<a onClick={(e) => doClick(e, index)}>
					<span>{item}</span>
				</a>
			</li>
		);
	});

	return <ul className="tabs-header">{tabs}</ul>;
};

export default TabHeader;
