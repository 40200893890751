import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import ImageSlider from "../components/ImageCarousel/ImageSlider";
import data from "../components/EventPage/EventData";



const EventDetails = () => {
    const {eventYear , eventId} = useParams() ;
    const [props , setProps] = useState(null) ;

    useEffect(() => {
      data.forEach(d=> {
        if (d.year === Number(eventYear)) {
          setProps(d.events[eventId-1]) ;
        }
      })
    }, [eventYear,eventId]);

    // console.log(props.moreDetails);

  return (
    <div className="landing h-full px-10 pt-40 pb-5 md:px-32 text-white" id="landing-section">
      {props ? (
        <>
      <div className="flex flex-col md:flex-row items-center md:items-start gap-10 m-3">
        <img
          className=" w-96 h-96 md:w-[28%] md:max-h-[28%] object-fit rounded-lg"
          src={props.poster} alt="photo" />
        <div className="sm:text-center md:text-left">


          <h1 className="text-4xl font-bold text-400">{props.title.split(' ')[0]} <span className="color-secondary">{props.title.split(' ').slice(1).join(' ')}</span></h1>
          
          <hr className=" h-1 my-2 border-[3px]" />
          <p className="my-2 font-normal tracking-wide	font-poppins "
          >{props.content}</p>
        </div>
      </div>
      <div className="my-20 sm:text-center md:text-left">
        <h1 className="text-3xl font-bold">More <span className="color-secondary">Details</span> </h1>
        <hr className="h-1 my-2 border-[3px]" />
        <p className="my-2 font-normal tracking-wide	font-poppins">{props.moreDetails}</p>
      </div>
      <div>
        <h1 className="text-3xl font-bold">Event at a <span className="color-secondary">Glance</span></h1>
        <hr className="h-1 my-2 border-[3px]" />
        <div className="my-5">
          <ImageSlider 
            photoGallery = {props.photoGallery}
            />
        </div>
      </div>
      </>

      ) : null }
    </div>
    // <h1>hii</h1>
  );
};

export default EventDetails ;