import React from "react";
import "./facultySection.css";
const FacultySection = () => {
  return (
    <div className="faculty-advisor-container" id="fac-section">
      <div className="faculty-advisor-text">
        <h1 className="fac-advisor-heading">
          <p className="faculty-text">
            <span className="purp-color">Fac</span>
            ulty
          </p>
          <p>
            {" "}
            Advi
            <span className="purp-color">sor</span>
          </p>
        </h1>
      </div>
      <div className="faculty-advisor-iamge">
        <div className="fac-img-div subrata fac-up">
          <img
            src="https://nitdgp.ac.in/uploads/abd4fd9036f1223038b2c9643dad0f18.jpeg"
            alt="Subrata"
            className="fac-img "
          />
          <h3>Prof. Subrata Nandi</h3>
          <h6>Counselor</h6>
        </div>
        <div className="fac-img-div sajal fac-dwn">
          <img
            alt="Sajal"
            src="https://freeimghost.net/images/2023/04/16/WhatsApp-Image-2023-04-17-at-01.17.05.webp"
            className="fac-img "
          />
          <h3>Prof. Sajal Mukhopadhyay</h3>
          <h6>Faculty Advisor</h6>
        </div>
        <div className="fac-img-div parag fac-up">
          <img
            src="https://freeimghost.net/images/2023/04/16/WhatsApp-Image-2023-04-17-at-01.17.23.webp"
            alt="Parag"
            className="fac-img "
          />
          <h3>Prof. Parag Kumar Guhathakurta</h3>
          <h6>Faculty Advisor</h6>
        </div>
        <div className="fac-img-div animesh fac-dwn">
          <img
            src="https://nitdgp.ac.in/uploads/Animesh_photo.jpg"
            alt="Animesh"
            className="fac-img "
          />
          <h3>Prof. Animesh Dutta</h3>
          <h6>Faculty Advisor</h6>
        </div>
      </div>
    </div>
  );
};

export default FacultySection;
