import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import EventCard from "./EventCard";
import cards from './EventData'


const AllEvents = () => {
  return (
    <div>
        {cards?.map((item, index) => (
          <>

        <div class="relative flex px-5 items-center mt-10">
          <div class="flex-grow border-t border-gray-400"></div>
          <h2 class="flex-shrink mx-4 font-bold text-white bg-transparent text-xl" >{item.year}</h2>
          <div class="flex-grow border-t border-gray-400"></div>
        </div>

          <EventCard
            year = {item.year}
            events = {item.events}
          />

        </>
        ))}

    </div>
  );
};

export default AllEvents;
