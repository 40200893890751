import React, { useState } from "react";
import TabHeader from "../components/Tabs/Tabs";
import HexGrid from "../components/HexGrid/HexGrid";
import "../components/Tabs/Tabs.css";

const Team = () => {

  const headers = ["Final Year", "Third Year", "PhD", "Alumni 2024","Alumini 2023"];


  const [activeTab, setActiveTab] = useState(0);
  // const [tabData, setTabData] = useState(headers);
  const changeTabOnClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div className="background-clip pt-[60px]">
      {/* <div className="w-full absolute -z-10 top-0 h-[100vh]"></div> */}
      <div className="about-team my-[3rem] px-[2rem] flex flex-col md:flex-row items-center justify-between gap-5">
        <div className="basis-1 md:basis-1/2">
          <h1 className="text-6xl mb-2 font-bold text-white">
            Our <span className="team-text">Team</span>
          </h1>
          <span className="text-white">
            Our team is made up of highly skilled individuals who are
            enthusiastic about their respective fields. We value each other's
            exceptional skills and enthusiasm, which allows us to work together
            to achieve great results.{" "}
          </span>
        </div>
        <div className="basis-1/2 w-full">
          {/* <Tabs headers={headers} /> */}

          <div className="tabs-body">
            <TabHeader
              data={headers}
              click={changeTabOnClick}
              activeId={activeTab}
            />
            {/* <TabContent data={tabData} activeId={activeTab} /> */}
          </div>
        </div>
      </div>
      <HexGrid yearindx={activeTab} />
      {/* <div className="bottom-clip w-full h-[100px]"></div> */}
    </div>
  );
};

export default Team;
