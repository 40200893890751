import React from "react";
import LoaderGif from "../../assets/Pinwheel.gif";
import "./loader.css";
const Loader = () => {
  return (
    <div className="loader-div">
      <img alt="Loader" src={LoaderGif} className="loader-gif" />
    </div>
  );
};

export default Loader;
