import React from 'react'
import upcomingEventImage from '../../assets/KnowYourDept.png'
import { Link } from 'react-router-dom'

const IsUpcomingEvent = false;

const UpcomingEvent = () => {
  return (
    <div className="h-full px-10 pt-5 pb-20 md:px-32 text-white">
    {
      IsUpcomingEvent ? (
      <div className="flex flex-col md:flex-row items-center md:items-start gap-10">
        <img
          className="w-96 h-96 md:w-[28%] md:h-[28%] object-fit rounded-lg"
          src={upcomingEventImage} alt="photo" />
        <div className="sm:text-center md:text-left">
          <h1 className="text-4xl font-bold text-violet-400">Career BootCamp</h1>
          <hr className="text-violet-400 h-1 my-2 border-[3px]" />
          <p className="my-2 font-poppins font-light font-normal"
          >Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae atque ut dolorem, beatae optio labore quaerat dicta minima non, sit accusantium ad quis. Ipsa numquam fugit nostrum libero atque ab
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem, deleniti sunt. Dolorum, nobis reprehenderit ducimus voluptatem itaque deserunt, consectetur inventore dolores aperiam eum autem suscipit! Possimus perspiciatis nobis non sapiente commodi eaque culpa alias reiciendis dolorem, laudantium rem minus temporibus voluptas! Est, maxime impedit in doloremque doloribus veritatis illo reprehenderit?
            .</p>


            <div className="team-home-div-textArea mt-10">
                <Link to="/" className="team-home-all">
                <span>Read Details</span>
                <p id="teamMore">{">>"}</p>
                </Link>
            </div>

        </div>
        </div>
      ) : (
          <div className="">
            <div id="landingText1">To Be Announced Soon....</div>
          </div>
      )
    }
    </div>
    
  )
}

export default UpcomingEvent