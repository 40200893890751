import React from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import IndivisualEventCard from "./IndivisualEventCard";

var settings = {
  dots: true,
  speed: 500,
  slidesToShow: 3,
  initialSlide: 0,
  slidesToScroll: 1,
  autoplay: true,
  arrow: false,
  infinite: true,
  swipeToSlide: true,
  pauseOnHover: true,
  autoplaySpeed: 3000,
  cssEase: "linear",
  responsive: [
    {
      breakpoint: 1120,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 610,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 430,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
const EventCard = (props) => {
  // console.log(props.events)
  const card = props.events ;
  
  return (
    <div >
      <Slider {...settings}>
        {card.map((item, index) => (
          <div className="mb-16 px-5 ">
            <IndivisualEventCard
              src={item.poster}
              year = {props.year}
              id = {item.id}
              title = {item.title}
              content = {item.content}
              moreDetails = {item.moreDetails}
              speakers = {item.speakers}
              photoGallery = {item.photoGallery}
            />

          </div>
        ))}
      </Slider>
    </div>
  );
};

export default EventCard;
