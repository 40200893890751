import React from "react";
import AllEvents from "../components/EventPage/AllEvents";
import "../components/Tabs/Tabs.css";

import UpcomingEvent from "../components/UpcomingEvent/UpcomingEvent";

const Events = () => {
  return (
    <div className="landing min-h-screen max-h-full background-clip pt-[60px]" id="landing-section">
      <div className="landingContent p-5 text-cente font-poppins ">
        <div className=" text-center ">                
          <h1 className="text-6xl mb-2 font-bold text-white">
            Our <span className="team-text">Events</span>
          </h1>
        </div>

        <span className="mt-4 font-poppins font-normal text-center tracking-wide ">Welcome to the hub of excitement and learning, where CSS (Computer Science Society) events unfold to ignite your passion for technology and camaraderie!</span>
      </div>

      {/* horizontal line through Upcoming */}

      <div class="relative flex px-5 items-center">
        <div class="flex-grow border-t border-gray-400"></div>
        <h2 class="flex-shrink mx-4 font-bold text-white bg-transparent text-bold text-xl" >Upcoming</h2>
        <div class="flex-grow border-t border-gray-400"></div>
      </div>

      {/* Upcoming event details */}

      <UpcomingEvent 
      />

      {/* previous events */}

      <div className="py-10 px-16">
        <AllEvents/>
      </div>

    </div>
  );
};

export default Events;
