import React from 'react'

import { Link } from 'react-router-dom'




const IndivisualEventCard = (props) => {

  return (


<div class="flex mt-16 mb-5 items-center justify-center bg-transparent pb-9" >
  <div class="group h-[200px] w-[300px] " >
    <div class="group relative rounded-xl mx-6 md:mx-0 cursor-pointer items-center justify-center overflow-hidden shadow-md " style={{ boxShadow: '0 0 10px var(--color-secondary)' }}>
      <div class="h-96 w-72 md:w-full md:h-80">
        <img
          class="h-full w-full object-fit transition-transform duration-500 group-hover:scale-125 group-hover:blur-[2px]"
          src={props.src}
          alt=""
        />
      </div>
      <div class="absolute inset-0 bg-gradient-to-b from-transparent via-transparent group-hover:from-black/70 group-hover:via-black/60 group-hover:to-black/70"></div>
      <div class="absolute inset-0 flex translate-y-[100%] flex-col items-center justify-center px-9 text-center transition-all duration-500 group-hover:translate-y-0">
        <h1 class="text-2xl font-bold mb-3 text-white font-poppins">{props.title}</h1>
        <p class="text-md max-h-12 line-clamp-3 font-poppins overflow-ellipsis overflow-hidden text-white">{props.content}</p>
        <div className="team-home-div-textArea mt-10">
          <Link to={`/event/${props.year}/${props.id}`} className="team-home-all before:top-4 before:h-8">
            <span className="">Read More</span>
            <p id="teamMore">{">>"}</p>
          </Link>
        </div>
      </div>
    </div>
  </div>
</div>


  )
}

export default IndivisualEventCard