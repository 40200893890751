import React from "react";
import { Link } from "react-router-dom";
import "./teamsSection.css";
import Carousel from "../Carousel/Carousel";

export default function TeamsSection() {
  return (
    <div className="team-home-div" id="teams-section">
      <div className="team-home-div-textArea home-section-heading">
        <h1 className="color-white">
          <span className="color-secondary"> Post</span> Bearers
        </h1>
      </div>
      <div className="carousel-div">
        <Carousel />
      </div>
      <div className="team-home-div-textArea">
        <Link to="/team" className="team-home-all">
          <span>Meet all members</span>
          <p id="teamMore">{">>"}</p>
        </Link>
      </div>
    </div>
  );
}
