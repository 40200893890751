import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import CSSlogo from "../../assets/CSS_logo2.png";
import "./navbar.css";
import { useState } from "react";
export default function Navbarcomp() {
	const [color, setColor] = useState(false);
	const changeNavbarColor = () => {
		let h = window.location.href.split("/")[3] === "team" ? 50 : 300;
		if (window.scrollY >= h) {
			setColor(true);
		} else {
			setColor(false);
		}
	};
	window.addEventListener("scroll", changeNavbarColor);

	return (
		<div className="navdiv">
			{/* use bg="light" and varient="light" for light theme of navbar */}
			<Navbar
				collapseOnSelect
				expand="lg"
				className={color ? "nav blacknav navbar-dark" : "nav navbar-dark"}
			>
				<Container>
					<Navbar.Brand href="/">
						<img
							src={CSSlogo}
							className="d-inline-block align-top"
							id="navCSSlogo"
							alt="CSS"
						/>
					</Navbar.Brand>

					<Navbar.Toggle aria-controls="responsive-navbar-nav" />

					<Navbar.Collapse id="responsive-navbar-nav">
						<Nav className="me-auto"></Nav>
						<Nav>
							<Nav.Link href="/">HOME</Nav.Link>
							<Nav.Link eventKey={2} href="/event">
								EVENTS
							</Nav.Link>
							<Nav.Link eventKey={3} href="/team">
								TEAM
							</Nav.Link>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</div>
	);
}
