import eventImg from "../../assets/KnowYourDept.png";

const data = [
  // year --
  {
    // all events year wise...
    id: 1,
    year: 2024,
    events: [
      {
        id: 1,
        poster: "/eventImages/2024/resume building/poster.jpg",
        title: "Resume Building",
        content:
          "In 2024, the Computer Science Society (CSS) at the National Institute of Technology, Durgapur, took another proactive step in grooming the professional skills of its students by organizing a comprehensive Resume Building workshop. This pivotal event, marking the second iteration hosted by CSS, was designed to equip students from all branches and years with the essential knowledge and techniques required to craft compelling resumes. With the job market becoming increasingly competitive, CSS recognized the importance of not just academic prowess but also the ability to effectively showcase one's skills and experiences on paper. The workshop featured a diverse array of sessions led by esteemed alumni who generously shared their invaluable insights and firsthand experiences. From formatting tips to content selection strategies, participants gained invaluable guidance on how to tailor their resumes to stand out in a crowded field.",
        moreDetails:
          "Through a series of engaging demonstrations and interactive discussions, students had the opportunity to learn from esteemed alumni who generously shared their expertise and success stories. From highlighting key achievements to tailoring content for specific job roles, attendees gained practical insights into optimizing their resumes for maximum impact. As CSS continues to foster a culture of professional development and career readiness, events like Resume Building play a crucial role in equipping students with the skills and confidence needed to excel in the ever-evolving job market.",

        speakers: [eventImg, eventImg, eventImg],
        photoGallery: [
          "/eventImages/2024/resume building/1.jpg",
          "/eventImages/2024/resume building/2.jpg",
          "/eventImages/2024/resume building/3.jpg",
          "/eventImages/2024/resume building/4.jpg",
          "/eventImages/2024/resume building/5.jpg",
          "/eventImages/2024/resume building/6.jpg",
        ],
      },
      {
        id: 2,
        poster: "/eventImages/2024/Know Your Department/poster.jpg",
        title: "Know Your Department 2.0",
        content:
          "In 2024, the Computer Science Society (CSS) at the National Institute of Technology, Durgapur, orchestrated another enlightening event titled Know Your Department. This initiative was tailored specifically for CSE sophomores and juniors, aiming to provide them with invaluable insights into the intricacies of the Computer Science department and the myriad career pathways that lay ahead. Serving as the second edition of this insightful endeavor, CSS once again demonstrated its commitment to nurturing and empowering the student body with knowledge and foresight. Through engaging discussions, informative sessions, and interactive workshops, participants were equipped with the tools and understanding necessary to navigate their academic journey and chart a course towards a successful career in the field of Computer Science.",
        moreDetails:
          "As CSS continues to champion initiatives that enrich and empower students, Know Your Department stands as a beacon of guidance and inspiration for the budding technologists of tomorrow. For more details , you can visit our official page to stay  up to date.",
        speakers: [eventImg, eventImg, eventImg],
        photoGallery: [
          "/eventImages/2024/Know Your Department/1.jpg",
          "/eventImages/2024/Know Your Department/2.jpg",
          "/eventImages/2024/Know Your Department/3.jpg",
          "/eventImages/2024/Know Your Department/4.jpg",
        ],
      },
      {
        id: 3,
        poster: "/eventImages/2024/CSS Freshers/poster.jpg",
        title: "Freshers",
        content:
          "In 2024, the National Institute of Technology, Durgapur's Computer Science Society (CSS) once again showcased their knack for hosting an unforgettable fresher's party. This time around, it marked the second consecutive year of CSS spearheading this vibrant event, setting the bar even higher. The evening was brimming with excitement as CSE sophomores mingled with the fresh faces, creating an atmosphere pulsating with youthful energy and camaraderie. From interactive icebreakers to engaging group activities, there was never a dull moment as everyone embraced the spirit of celebration. The highlight of the night was undoubtedly the glamorous ramp walk, where the contestants vied for the titles of Mr. and Ms. Freshers, showcasing not just style, but also confidence and charisma",
        moreDetails:
          "The Freshers Party featured a plethora of fun activities that fostered connections and laughter among attendees, creating lasting bonds within the community. A heartfelt thank you to SPIC MACAY , SMF Creatives and the Music Club for their captivating performances at the Freshers' Party, showcasing cultural diversity and musical talent, leaving an indelible mark on our university community.  The crowning moment arrived with the much-anticipated Mr. and Ms. Freshers ramp walk, where participants exuded confidence and grace, captivating the audience with their charm. As the night progressed, gifts were exchanged, laughter echoed, and friendships blossomed, leaving an indelible mark on the hearts of all who attended.",
        speakers: [eventImg, eventImg, eventImg],
        photoGallery: [
          "/eventImages/2024/CSS Freshers/1.jpg",
          "/eventImages/2024/CSS Freshers/2.jpg",
          "/eventImages/2024/CSS Freshers/3.jpg",
          "/eventImages/2024/CSS Freshers/4.jpg",
        ],
      },
    ],
  },
  {
    // all events year wise...
    id: 1,
    year: 2023,
    events: [
      {
        id: 1,
        poster: "/eventImages/2023/career bootcamp/poster.jpg",
        title: "Career Bootcamp",
        content:
          "Career Bootcamp is a comprehensive event, organized by CSS, covering CV feedback, LinkedIn optimization, CSE job exploration, and research opportunities. They get a chance to interact with the alumni who have already achieved many milestones both in the corporate world and research field. This event is open to all branches. So core branch students also get an insightful guide for their upcoming career. To get the  upcoming schedule and workflow, you can always visit our official page.",
        moreDetails:
          "The Career Bootcamp, hosted by the Computer Science Students Society (CSS), offers a dynamic platform for students to enhance their career prospects.This event is open to all branches. It promises valuable career insights and networking.",
        speakers: [eventImg, eventImg, eventImg],
        photoGallery: [
          "/eventImages/2023/career bootcamp/1.jpg",
          "/eventImages/2023/career bootcamp/2.jpg",
          "/eventImages/2023/career bootcamp/3.jpg",
          "/eventImages/2023/career bootcamp/4.jpg",
          "/eventImages/2023/career bootcamp/5.jpg",
          "/eventImages/2023/career bootcamp/6.jpg",
          "/eventImages/2023/career bootcamp/7.jpg",
          "/eventImages/2023/career bootcamp/8.jpg",
        ],
      },
      {
        id: 2,
        poster: "/eventImages/2023/Know Your Department/poster.jpg",
        title: "Know Your Department",
        content:
          "The Computer Science Society (CSS) Club at the National Institute of Technology, Durgapur, organized a special event titled Know Your Department aimed at providing valuable insights and guidance to the freshers and sophomores of the Computer Science and Engineering (CSE) department. The event kicked off with an extended introductory session, where senior students and faculty members warmly welcomed the newcomers, setting the stage for an engaging and informative session ahead.The event primarily focused on familiarizing the attendees with various facets of the CSE department, ranging from academic curriculum and research opportunities to extracurricular activities and career prospects. Through interactive presentations, panel discussions, and Q&A sessions, the participants gained valuable insights into the diverse avenues available within the field of computer science and the resources available to support their academic and professional growth",
        moreDetails:
          "As the event drew to a close, the attendees departed with a newfound sense of clarity, inspiration, and camaraderie, ready to embark on their academic journey with confidence and enthusiasm. The Know Your Department event served as a testament to the CSS Club's commitment to nurturing a supportive and inclusive community within the CSE department, ensuring that every student feels empowered to reach their fullest potential. For more details, visit our official page.",
        speakers: [eventImg, eventImg, eventImg],
        photoGallery: [
          "/eventImages/2023/Know Your Department/1.jpg",
          "/eventImages/2023/Know Your Department/2.jpg",
          "/eventImages/2023/Know Your Department/3.jpg",
          "/eventImages/2023/Know Your Department/4.jpg",
        ],
      },
      {
        id: 3,
        poster: "/eventImages/2023/Freshers/poster.jpg",
        title: "Freshers",
        content:
          "The Computer Science Society (CSS) Club at the National Institute of Technology, Durgapur, hosted an exhilarating Freshers Party, dedicated to welcoming the second-year Computer Science and Engineering (CSE) sophomores into a vibrant and inclusive community. The event unfolded as a kaleidoscope of performances, gifts, and spirited interactions, with students from various clubs on campus coming together to create an unforgettable experience.The Freshers Party kicked off with an electrifying atmosphere, as students eagerly gathered to celebrate the beginning of a new academic year and to extend a warm welcome to their fellow sophomores. The venue buzzed with excitement and anticipation as attendees immersed themselves in the festivities, eager to partake in the evening's lineup of entertainment and camaraderie.",
        moreDetails:
          "Furthermore, the Freshers Party provided a platform for students to forge new friendships and connections, transcending boundaries and fostering a sense of belonging within the CSE community. A heartfelt thank you to SPIC MACAY , SMF Creatives and the Music Club for their captivating performances at the Freshers' Party, showcasing cultural diversity and musical talent, leaving an indelible mark on our university community. As students mingled and interacted, sharing laughs and stories, they laid the groundwork for lasting relationships that would enrich their college experience and beyond.In conclusion, the Freshers Party organized by the CSS Club at NIT DGP was a resounding success, embodying the spirit of unity, creativity, and inclusivity that defines the campus culture. For more details, visit our official page",
        speakers: [eventImg, eventImg, eventImg],
        photoGallery: [
          "/eventImages/2023/Freshers/1-min.JPG",
          "/eventImages/2023/Freshers/2-min.JPG",
          "/eventImages/2023/Freshers/3-min.JPG",
          "/eventImages/2023/Freshers/4-min.JPG",
          "/eventImages/2023/Freshers/5-min.JPG",
          "/eventImages/2023/Freshers/6-min.JPG",
        ],
      },
      {
        id: 4,
        poster: "/eventImages/2023/Farewell/poster.jpg",
        title: "Farewell",
        content:
          "The Computer Science Society (CSS) Club at the National Institute of Technology, Durgapur, orchestrated a touching farewell event to honor the graduating batch of Computer Science and Engineering (CSE) students, marking the end of an era and the beginning of new adventures. The farewell proceedings commenced with a heartfelt welcome extended to the final year students, accompanied by nostalgic reflections on their journey through the hallowed halls of the institute. As the graduating seniors took their seats amidst a sea of emotions, the ambiance was charged with a mix of excitement, pride, and a tinge of bittersweet nostalgia. The event unfolded as a poignant tribute to the departing students, with speeches and anecdotes shared by faculty members, alumni, and fellow classmates, highlighting their academic achievements, personal growth, and contributions to the department and the campus community at large.",
        moreDetails:
          "In addition to the heartfelt words spoken, the farewell ceremony also featured a variety of symbolic gestures and tokens of appreciation, ranging from personalized plaques and certificates to humorous awards and keepsakes, all meticulously crafted to encapsulate the essence of each student's journey and impact. As the evening progressed, emotions ran high as students and faculty alike expressed their best wishes for the future endeavors of the departing seniors. For more details, visit our official page. A heartfelt thank you to SPIC MACAY , SMF Creatives and the Music Club for their captivating performances at the Freshers' Party, showcasing cultural diversity and musical talent, leaving an indelible mark on our university community.",
        speakers: [eventImg, eventImg, eventImg],
        photoGallery: [
          "/eventImages/2023/Farewell/1.jpg",
          "/eventImages/2023/Farewell/2.jpg",
          "/eventImages/2023/Farewell/3.jpg",
          "/eventImages/2023/Farewell/4.jpg",
          "/eventImages/2023/Farewell/5.jpg",
          "/eventImages/2023/Farewell/6.jpg",
          "/eventImages/2023/Farewell/7.jpg",
          "/eventImages/2023/Farewell/8.jpg",
        ],
      },
      {
        id: 5,
        poster: "/eventImages/2023/Teachers Day/poster.jpg",
        title: "Teachers Day",
        content:
          "In 2024, the Computer Science Society (CSS) at the National Institute of Technology, Durgapur, hosted an enlightening Technical Talk event for the second consecutive year, focusing on empowering CSE students with invaluable insights into the tech industry. This eagerly anticipated gathering served as a platform for students to delve deeper into the intricacies of the field, with a particular emphasis on career advancement and academic pursuits. The event featured a lineup of distinguished alumni who graciously shared their wealth of knowledge and real-world experiences, offering invaluable advice on navigating the competitive landscape of job opportunities at top tech giants like FAANG. From insider tips on acing technical interviews to strategies for personal and professional development, attendees received a comprehensive roadmap to success in the ever-evolving tech industry. Additionally, the event catered to students aspiring to pursue higher education by hosting a dedicated session on the nuances of applying for MS/PhD programs, providing invaluable guidance on academic research, application procedures, and career prospects in academia. ",
        moreDetails:
          "Moreover, the event catered to the aspirations of those considering further academic pursuits, with a dedicated session providing invaluable guidance on navigating the complexities of MS/PhD applications and exploring the rewarding path of academic research. As CSS continues to empower students with knowledge and opportunities, the Technical Talk event reaffirms its role as a catalyst for success in the dynamic realm of technology",
        speakers: [eventImg, eventImg, eventImg],
        photoGallery: [
          "/eventImages/2023/Teachers Day/1.jpg",
          "/eventImages/2023/Teachers Day/2.jpg",
          "/eventImages/2023/Teachers Day/3.jpg",
          "/eventImages/2023/Teachers Day/poster.jpg",
        ],
      },
      {
        id: 6,
        poster: "/eventImages/2023/Technical Talk/poster.jpg",
        title: "Technical Talk",
        content:
          "The CSS Club at NIT DGP recently organized a vibrant celebration for Teacher's Day, dedicated to honoring the esteemed Computer Science and Engineering (CSE) faculty members. The event was a heartfelt gesture from students to express gratitude towards their mentors. It featured engaging performances from both students and teachers, creating a lively atmosphere filled with appreciation and camaraderie. Additionally, students presented thoughtful gifts to their beloved faculty members, further symbolizing their respect and admiration",
        moreDetails:
          "the Teacher's Day celebration organized by the CSS Club at NIT DGP was a resounding success, serving as a poignant reminder of the enduring bonds forged between students and teachers in their shared journey of learning and growth. Every year it is organized with full enthusiasm. For more details, visit our official page.",
        speakers: [eventImg, eventImg, eventImg],
        photoGallery: [],
      },
    ],
  },
];

export default data;
