import React from "react";
// import './homePage.css'
import AnchorLink from "react-anchor-link-smooth-scroll";
import LandingSection from "../components/LandingSection/LandingSection";
import AboutSection from "../components/About/aboutSection";
import EventSection from "../components/EventSection/eventSection";
import TeamsSection from "../components/TeamsSection/teamsSection";
import FacultySection from "../components/FacultySection/facultySection";
import {
  BsInstagram,
  BsLinkedin,
  BsYoutube,
  BsFacebook,
  BsFillCircleFill,
} from "react-icons/bs";

export default function homePage() {
  return (
    <div className="home">
      <div className="stickyNav">
        <div className="stickyNavItem">
          <AnchorLink href="#landing-section">
            <BsFillCircleFill className="stickyNavIcon"></BsFillCircleFill>
          </AnchorLink>
        </div>
        <div className="stickyNavItem">
          <AnchorLink href="#about-section">
            <BsFillCircleFill className="stickyNavIcon"></BsFillCircleFill>
          </AnchorLink>
        </div>
        <div className="stickyNavItem">
          <AnchorLink href="#event-section">
            <BsFillCircleFill className="stickyNavIcon"></BsFillCircleFill>
          </AnchorLink>
        </div>
        <div className="stickyNavItem">
          <AnchorLink href="#teams-section">
            <BsFillCircleFill className="stickyNavIcon"></BsFillCircleFill>
          </AnchorLink>
        </div>
        <div className="stickyNavItem">
          <AnchorLink href="#fac-section">
            <BsFillCircleFill className="stickyNavIcon"></BsFillCircleFill>
          </AnchorLink>
        </div>
        <div className="stickyNavItem">
          <AnchorLink href="#footer">
            <BsFillCircleFill className="stickyNavIcon"></BsFillCircleFill>
          </AnchorLink>
        </div>
      </div>
      <div className="stickySocial">
        <div className="stickySocialItem">
          <a href="https://instagram.com/cssnitdgp">
            <BsInstagram className="stickysocialicon"></BsInstagram>
          </a>
        </div>
        <div className="stickySocialItem">
          <a href="https://www.facebook.com/profile.php?id=100089945320887">
            <BsFacebook className="stickysocialicon"></BsFacebook>
          </a>
        </div>
        <div className="stickySocialItem">
          <a href="https://www.youtube.com/channel/UCZtQPKNyNZY0OpPUZEWYwRg">
            <BsYoutube className="stickysocialicon"></BsYoutube>
          </a>
        </div>
        <div className="stickySocialItem">
          <a href="https://www.linkedin.com/company/cssnitdgp/">
            <BsLinkedin className="stickysocialicon"></BsLinkedin>
          </a>
        </div>
      </div>
      <LandingSection />

      <AboutSection />
      <EventSection />
      <TeamsSection />
      <FacultySection />
    </div>
  );
}
