import React from "react";
import "./aboutSection.css";

export default function aboutSection() {
  return (
    <div className="about" id="about-section">
      <div className="aboutHeadline">
        About <span className="color-secondary">Us</span>
      </div>
      <div className="flexabout">
        <div className="flexaboutbox" id="about">
          <div className="whoareweHeadline">
            WHO <span className="color-secondary headlineSpan">ARE WE</span>
          </div>
          <div className="whoareweContent">
            We are a community of passionate computer science students. We are
            builders, dreamers, problem-solvers. We are here to grow and
            strengthen the Computer Science Student community here at NIT DGP
            and connect our members to all the people, resources, and
            opportunities they want to achieve their goals.
          </div>
        </div>

        <div className="flexaboutbox" id="vision">
          <div className="visionHeadline">
            OUR <span className="color-secondary headlineSpan">VISION</span>{" "}
          </div>
          <div className="visionContent">
            Our vision is to create an inclusive environment for the CSE
            community and beyond, offering resources and events that foster
            learning and professional development. We aim to organize diverse
            events and workshops for all students interested in computer science
            and related fields, from coding competitions and hackathons to talks
            on emerging tech trends. Additionally, we aim to connect students
            with alumni regardless of academic background.
          </div>
        </div>

        <div className="flexaboutbox" id="journey">
          <div className="journeyHeadline">
            OUR <span className="color-secondary headlineSpan">JOURNEY</span>{" "}
          </div>
          <div className="JourneyContent">
            Our journey as a CSE students society has been one of development
            and community building. We began as a small group of like-minded
            individuals with a passion for technology, and we have since grown
            into a thriving community of students and faculty. We have organised
            events, seminars, and guest lectures to provide opportunities for
            learning and networking. Our journey continues as we strive to
            inspire and support the next generation of CSE professionals.
          </div>
        </div>
      </div>
    </div>
  );
}
