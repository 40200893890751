import React from "react";
import Image11 from "../../assets/nat-1-large_.jpg";
import Image21 from "../../assets/KnowYourDept.png";
import Image31 from "../../assets/nat-3-large_.jpg";
import "./eventSection.css";
import { useState } from "react";
import { useRef } from "react";

export default function EventSection() {
  const event1 = useRef();
  const event2 = useRef();
  const event3 = useRef();

  const [content, setContent] = useState(
    "Greetings! We are excited to invite you to a technical talk by Dr. Niloy Ganguly, a Professor in the Dept. of Computer Science and Engineering at IIT Kharagpur and a visiting professor at the Leibniz University of Hannover. Dr. Ganguly is a Fellow of the Indian Academy of Engineering and has a wealth of experience and expertise in Social Computing, Machine Learning, and Network Science. In this talk, Dr. Ganguly will present a pre-trained graph neural network framework for crystalline materials, aimed at addressing the challenges of rapid and accurate prediction"
  );

  const handleMouseOver1 = () => {
    setContent(
      "Unleash the fun and excitement as CSS (CSE Student's Society) is hosting a grand Fresher's party exclusively for the 2nd year CSE students!Get ready to take a break from the monotony of classNamees and assignments and bring some fun into your lives."
    );
    event1.current.style.zIndex = "20";
    event2.current.style.zIndex = "10";
    event3.current.style.zIndex = "10";
  };

  const handleMouseOver2 = () => {
    setContent(
      "Welcome to the exciting world of Computer Science and Engineering! As you embark on your journey towards a degree in CSE, it's important to know what your department has to offer and the limitless opportunities that lie ahead. That's why CSS is proud to present 'Know Your Department', an event designed exclusively for first-year CSE students. Well, get ready to be blown away because Know Your Department is an exciting opportunity for you to learn about the department, discover its infinite possibilities, and interact with your future professors.      "
    );
    event1.current.style.zIndex = "10";
    event2.current.style.zIndex = "20";
    event3.current.style.zIndex = "10";
  };
  const handleMouseOver3 = () => {
    setContent(
      "Greetings! We are excited to invite you to a technical talk by Dr. Niloy Ganguly, a Professor in the Dept. of Computer Science and Engineering at IIT Kharagpur and a visiting professor at the Leibniz University of Hannover. Dr. Ganguly is a Fellow of the Indian Academy of Engineering and has a wealth of experience and expertise in Social Computing, Machine Learning, and Network Science. In this talk, Dr. Ganguly will present a pre-trained graph neural network framework for crystalline materials, aimed at addressing the challenges of rapid and accurate prediction"
    );
    event1.current.style.zIndex = "10";
    event2.current.style.zIndex = "10";
    event3.current.style.zIndex = "20";
  };

  return (
    <div className="homeEvent" id="event-section">
      <div className="u-center-text">
        <h2 className="heading-secondary u-margin-bottom-big event-section-heading">
          <span className="color-secondary">Our</span> Events
        </h2>
      </div>
      <div className="row">
        <div className="col-1-of-2">
          {/* <h3 className="heading-tertiary u-margin-bottom-small">
            You're going to fall in love with nature
          </h3> */}
          <p className="paragraph">{content}</p>

          {/* <a href="/" className="btn-text">
            Learn more &rarr;
          </a> */}
        </div>

        <div className="col-1-of-2">
          <div className="composition">
            <img
              onMouseOver={handleMouseOver1}
              srcSet={`${Image11} 300w, ${Image11} 1000w`}
              sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
              alt="Photo1"
              className="composition__photo composition__photo--p1"
              src={Image11}
              ref={event1}
            />

            <img
              onMouseOver={handleMouseOver2}
              // onMouseOut={handleMouseOver1}
              srcSet={`${Image21} 300w, ${Image21} 1000w`}
              sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
              alt="Photo2"
              className="composition__photo composition__photo--p2"
              src={Image31}
              ref={event2}
            />

            <img
              onMouseOver={handleMouseOver3}
              // onMouseOut={handleMouseOver1}
              srcSet={`${Image31} 300w, ${Image31} 1000w`}
              sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
              alt="Photo3"
              className="composition__photo composition__photo--p3"
              src={Image31}
              ref={event3}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
