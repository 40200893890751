import React from "react";
import "./landingSection.css";
import { MdDoubleArrow } from "react-icons/md";

export default function landingSection() {
  function ScrollToAbout() {
    window.scrollTo({
      top: window.innerHeight - 76,
      behavior: "smooth",
    });
  }
  return (
    <div className="landing" id="landing-section">
      <div className="landingContent">
        <div id="landingText1">
          We Are <span className="landingSpan1">CSS</span>
        </div>
        <div id="landingText2">
          Engage
          <span className="landingSeperator"> &#8226; </span>
          Educate
          <span className="landingSeperator"> &#8226; </span>
          Inspire
        </div>
        <p className="landingText3">
          We are CSS, and our goal is to provide a platform for students to
          study, interact, and improve both personally and professionally.We aim
          to build a strong feeling of community among cse students, hold
          engaging events, and serve as a link between students and alumni.
        </p>
        <button className="scrollButton" onClick={ScrollToAbout}>
          <MdDoubleArrow id="downscroll"></MdDoubleArrow>
        </button>
      </div>
    </div>
  );
}
