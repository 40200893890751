import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
var settings = {
  dots: true,
  speed: 500,
  slidesToShow: 4,
  initialSlide: 0,
  slidesToScroll: 1,
  autoplay: true,
  arrow: false,
  infinite: true,
  swipeToSlide: true,
  pauseOnHover: true,
  autoplaySpeed: 3000,
  cssEase: "linear",
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const ImageSlider = (props) => {
  console.log(props.photoGallery);
  return (
    <div >
      <Slider  {...settings}>
        {
          props.photoGallery.map((item, i) => (
            <div className='px-2 py-3 ' >
              <img src={item} alt="photo" className='shadow-md object-fit h-80 w-72 mx-auto sm:w-72 sm:h-72 md:w-60 md:h-80 lg:h-72 lg:w-56 xl:h-80 xl:w-80 '   style={{ boxShadow: '0 0 5px var(--color-secondary)' }}/>
            </div>
          ))
        }
      </Slider>
    </div>
  )
}

export default ImageSlider ;