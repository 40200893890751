import React, { useState, useEffect } from "react";
// Libraries
import { Routes, Route } from "react-router-dom";

// Pages
import Loader from "./components/Loader/Loader";
import Home from "./pages/Home";
import Team from "./pages/Team";
import Event from "./pages/Events";
import Custom404 from "./pages/Custom404";
import EventDetails from "./pages/EventDetails";

// Components
import Navbar from "./components/Navbar/navbar";
import Footer from "./components/Footer/footer";
import "./App.css";

const App = () => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.addEventListener("load", () => {
      setIsLoading(false);
    });
  }, []);
  
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="App overflow-hidden">
          <Navbar />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/team" element={<Team />} />
            <Route exact path="/event" element={<Event />} />
            <Route path="/event/:eventYear/:eventId" element={<EventDetails />} />
            <Route exact path="/*" element={<Custom404 />} />
          </Routes>
          <Footer />
        </div>
      )}
    </>
  );
};

export default App;
